@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .btn-primary {
    @apply px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 
           transition-colors duration-200 font-medium;
  }
  
  .btn-secondary {
    @apply px-4 py-2 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 
           transition-colors duration-200 font-medium;
  }
  
  .input-field {
    @apply px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 
           focus:ring-blue-500 focus:border-blue-500 outline-none;
  }
  
  .card {
    @apply bg-white rounded-lg shadow-md p-6;
  }
  
  .table-header {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 
           uppercase tracking-wider;
  }
  
  .table-cell {
    @apply px-6 py-4 whitespace-nowrap text-sm text-gray-900;
  }
  
  .page-container {
    @apply min-h-screen bg-gray-50;
  }

  .content-container {
    @apply max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8;
  }

  .page-title {
    @apply text-3xl font-bold text-gray-900 mb-8;
  }

  .data-table {
    @apply min-w-full divide-y divide-gray-200;
  }

  .data-table-container {
    @apply bg-white rounded-lg shadow overflow-hidden;
  }

  .table-row-hover {
    @apply hover:bg-gray-50 transition-colors duration-200;
  }

  .status-badge {
    @apply px-2 py-1 text-xs font-semibold rounded-full;
  }

  .status-badge-success {
    @apply bg-green-100 text-green-800;
  }

  .status-badge-warning {
    @apply bg-yellow-100 text-yellow-800;
  }

  .status-badge-error {
    @apply bg-red-100 text-red-800;
  }

  .action-button {
    @apply inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm;
  }

  .icon-button {
    @apply p-1 rounded-full hover:bg-gray-100 transition-colors duration-200;
  }

  .dialog-content {
    @apply relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6;
  }
}
